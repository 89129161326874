import { useEffect } from "react";
import { usePrevious } from "react-use";
import {
  ActionButtonGroup,
  Banner,
  Box,
  Button,
  CheckboxField,
  DropdownOption,
  FormRow,
  FormSection,
  InlineMessage,
  Space,
  useForm,
  validators,
} from "design-system";
import { boolean, option, taskEither } from "fp-ts";
import {
  constant,
  constFalse,
  constNull,
  constTrue,
  pipe,
} from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Tenant, foldTenant } from "../../../globalDomain";
import { useFormatMessage } from "../../../intl";
import { useCommand } from "../../../useAPI";
import { OptionalEditMoneyField } from "../../../Common/OptionalEditFields/OptionalEditMoneyField";
import { OptionalEditDropdownField } from "../../../Common/OptionalEditFields/OptionalEditDropdownField";
import { OptionalEditTextField } from "../../../Common/OptionalEditFields/OptionalEditTextField";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../../../Common/selectDropdownOption";
import { useTenantCurrency } from "../../../Common/useTenantCurrency";
import { useValidators } from "../../../Common/useValidators";
import { AmountLimit } from "../../api";
import { CreditCard, ReworkCreditCard } from "../../domain";
import { SelectedApplicant } from "../../mortgageDashboardUtils";
import { useAppContext } from "../../../useAppContext";
import { checkRequiredFieldsProvided, resolveAmount, toFalse } from "./utils";
import * as api from "./api";
import { LiabilitiesVariant } from "./Liabilities";
import * as standardLoanApi from "../../../StandardLoan/ExpensesAndAdditionalIncome/ExpensesConfirmation/CurrentLiabilitiesDialog/api";
import { foldLiabilitiesVariant } from "../../../StandardLoan/domain";
import { useFormatReworkData } from "../../useFormatReworkData";

type Props = {
  selectedApplicant: SelectedApplicant;
  initialValues: Option<CreditCard>;
  onSave: (loan: CreditCard) => unknown;
  onCancel: () => unknown;
  onEdit: () => unknown;
  onRemove: () => unknown;
  isACPhase: boolean;
  isInteractingWithAnyForm: boolean;
  isViewMode: boolean;
  providersList: DropdownOption<NonEmptyString>[];
  amountLimits: {
    [key in "ccAndOvdCurrentBalance" | "ccAndOvdLimit"]: AmountLimit;
  };
  edit: boolean;
  variant: LiabilitiesVariant;
  reworkCreditCard: Option<ReworkCreditCard>;
};

type FormState = {
  currentBalance: Option<number>;
  creditCardLimit: Option<number>;
  repaidBeforeDisbursement: Option<boolean>;
  changeLimit: Option<boolean>;
  provider: Option<NonEmptyString>;
  contractNumber: Option<string>;
  newCreditCardLimit: Option<number>;
};

type FormSetup = {
  tenant: Tenant;
  isACPhase: boolean;
  edit: boolean;
  manuallyAdded: boolean;
};

function formStateFromInitialValues(
  initialValues: Option<CreditCard>
): FormState {
  return pipe(
    initialValues,
    option.fold(
      () => ({
        currentBalance: option.none,
        creditCardLimit: option.none,
        repaidBeforeDisbursement: option.none,
        changeLimit: option.none,
        provider: option.none,
        contractNumber: option.none,
        newCreditCardLimit: option.none,
      }),
      values => ({
        currentBalance: resolveAmount(values.currentBalance),
        creditCardLimit: resolveAmount(values.creditCardLimit),
        repaidBeforeDisbursement: values.repaidBeforeDisbursement,
        changeLimit: values.changeLimit,
        provider: values.provider,
        contractNumber: values.contractNumber,
        newCreditCardLimit: resolveAmount(values.newCreditCardLimit),
      })
    )
  );
}

const getChangeLimitDisbursementConflict = (
  repaidBeforeDisbursement: Option<boolean>,
  changeLimit: Option<boolean>
) => toFalse(changeLimit) && toFalse(repaidBeforeDisbursement);

const getDisplayedFields = (
  values: FormState,
  formSetup: FormSetup,
  variant: LiabilitiesVariant
): { [K in keyof FormState]: boolean } => ({
  currentBalance: true,
  creditCardLimit: true,
  repaidBeforeDisbursement: foldLiabilitiesVariant(variant, {
    onMtg: constTrue,
    onCf: constFalse,
    onOvd: constFalse,
  }),
  changeLimit: foldLiabilitiesVariant(variant, {
    onMtg: () => formSetup.tenant === "CZ",
    onCf: constFalse,
    onOvd: constFalse,
  }),
  provider:
    !getChangeLimitDisbursementConflict(
      values.repaidBeforeDisbursement,
      values.changeLimit
    ) &&
    (formSetup.edit || option.isSome(values.provider)) &&
    (toFalse(values.changeLimit) || toFalse(values.repaidBeforeDisbursement)),
  contractNumber:
    !getChangeLimitDisbursementConflict(
      values.repaidBeforeDisbursement,
      values.changeLimit
    ) &&
    formSetup.tenant === "CZ" &&
    (toFalse(values.changeLimit) || toFalse(values.repaidBeforeDisbursement)),
  newCreditCardLimit:
    !getChangeLimitDisbursementConflict(
      values.repaidBeforeDisbursement,
      values.changeLimit
    ) &&
    formSetup.tenant === "CZ" &&
    toFalse(values.changeLimit),
});

const getRequiredFields = (
  values: FormState,
  formSetup: FormSetup,
  variant: LiabilitiesVariant
): { [K in keyof FormState]: boolean } => ({
  ...getDisplayedFields(values, formSetup, variant),
  ...(!formSetup.isACPhase
    ? {
        provider: false,
        contractNumber: false,
      }
    : {}),
});

export function CreditCardForm(props: Props) {
  const formatMessage = useFormatMessage();
  const {
    apiParameters: { tenant },
    config: { r8EnableSimpleRefinancing },
  } = useAppContext();
  const {
    defined,
    definedNoExtract,
    nonNegativeNumber,
    amountMinMax,
    maxLength,
  } = useValidators();
  const currency = useTenantCurrency();

  const addCreditCard = useCommand(api.addCreditCard);
  const editCreditCard = useCommand(api.editCreditCard);
  const addCreditCardStandardLoan = useCommand(standardLoanApi.addCreditCard);
  const editCreditCardStandardLoan = useCommand(standardLoanApi.editCreditCard);

  const { formatIssues, formatFeedback } = useFormatReworkData(
    props.reworkCreditCard
  );

  const currentBalance = pipe(
    props.initialValues,
    option.fold(
      () => option.none,
      a => a.currentBalance
    )
  );
  const creditCard = pipe(
    props.initialValues,
    option.fold(
      () => option.none,
      a => a.creditCardLimit
    )
  );

  useEffect(() => {
    if (
      (r8EnableSimpleRefinancing && !option.isSome(currentBalance)) ||
      !option.isSome(creditCard)
    ) {
      props.onEdit();
    }
  }, [currentBalance, creditCard]);

  const formSetup = pipe(
    props.initialValues,
    option.fold(
      (): FormSetup => ({
        tenant,
        edit: props.edit,
        isACPhase: props.isACPhase,
        manuallyAdded: true,
      }),
      values => ({
        tenant,
        edit:
          props.edit ||
          !option.isSome(values.currentBalance) ||
          !option.isSome(values.creditCardLimit),
        isACPhase: props.isACPhase,
        manuallyAdded: values.manuallyAdded,
      })
    )
  );

  const { fieldProps, handleSubmit, setValues, values } = useForm(
    {
      initialValues: formStateFromInitialValues(props.initialValues),
      fieldValidators: function (values) {
        const requiredFields = getRequiredFields(
          values,
          formSetup,
          props.variant
        );
        return {
          provider: requiredFields.provider ? definedNoExtract() : undefined,
          currentBalance: validators.inSequence(
            defined(),
            nonNegativeNumber,
            amountMinMax(
              props.amountLimits.ccAndOvdCurrentBalance.min,
              props.amountLimits.ccAndOvdCurrentBalance.max
            )
          ),
          creditCardLimit: validators.inSequence(
            defined(),
            nonNegativeNumber,
            amountMinMax(
              props.amountLimits.ccAndOvdLimit.min,
              props.amountLimits.ccAndOvdLimit.max
            )
          ),
          repaidBeforeDisbursement: requiredFields.repaidBeforeDisbursement
            ? definedNoExtract()
            : undefined,
          changeLimit: requiredFields.repaidBeforeDisbursement
            ? definedNoExtract()
            : undefined,
          newCreditCardLimit: validators.foldPredicate(
            constant(requiredFields.newCreditCardLimit),
            constant(option.none),
            validators.inSequence(
              definedNoExtract(),
              validators.validateIfDefined(
                validators.inSequence(
                  nonNegativeNumber,
                  amountMinMax(0, 999999999)
                )
              )
            )
          ),
          contractNumber: requiredFields.contractNumber
            ? validators.inSequence(
                definedNoExtract(),
                validators.validateIfDefined(maxLength(200))
              )
            : validators.validateIfDefined(maxLength(200)),
        };
      },
    },
    {
      onSubmit: values =>
        pipe(
          !getChangeLimitDisbursementConflict(
            values.repaidBeforeDisbursement,
            values.changeLimit
          ),
          boolean.fold(
            () => taskEither.left("Error" as unknown),
            () =>
              pipe(
                {
                  applicantIndex: props.selectedApplicant.index,
                  currentBalance: { currency, amount: values.currentBalance },
                  creditCardLimit: { currency, amount: values.creditCardLimit },
                  repaidBeforeDisbursement: values.repaidBeforeDisbursement,
                  changeLimit: values.changeLimit,
                  provider: values.provider,
                  contractNumber: values.contractNumber,
                  newCreditCardLimit: pipe(
                    values.newCreditCardLimit,
                    option.map(amount => ({ currency, amount }))
                  ),
                },
                taskEither.of,
                taskEither.chain(newValues =>
                  pipe(
                    props.initialValues,
                    option.fold(
                      () =>
                        pipe(
                          foldLiabilitiesVariant(props.variant, {
                            onMtg: () =>
                              addCreditCard({
                                ...newValues,
                                manuallyAdded: true,
                              }),
                            onCf: () =>
                              addCreditCardStandardLoan({
                                currentBalance: newValues.currentBalance,
                                creditCardLimit: newValues.creditCardLimit,
                              }),
                            onOvd: () =>
                              addCreditCardStandardLoan({
                                currentBalance: newValues.currentBalance,
                                creditCardLimit: newValues.creditCardLimit,
                              }),
                          }),
                          taskEither.chain(creditCard =>
                            taskEither.fromIO(() => props.onSave(creditCard))
                          )
                        ),
                      initialValues =>
                        pipe(
                          foldLiabilitiesVariant(props.variant, {
                            onMtg: () =>
                              editCreditCard({
                                ...newValues,
                                manuallyAdded: formSetup.manuallyAdded,
                                recordId: initialValues.recordId,
                              }),
                            onCf: () =>
                              editCreditCardStandardLoan({
                                currentBalance: newValues.currentBalance,
                                creditCardLimit: newValues.creditCardLimit,
                                recordId: initialValues.recordId,
                              }),
                            onOvd: () =>
                              editCreditCardStandardLoan({
                                currentBalance: newValues.currentBalance,
                                creditCardLimit: newValues.creditCardLimit,
                                recordId: initialValues.recordId,
                              }),
                          }),
                          taskEither.chain(creditCard =>
                            taskEither.fromIO(() => props.onSave(creditCard))
                          )
                        )
                    )
                  )
                )
              )
          )
        ),
    }
  );

  const previousEdit = usePrevious(formSetup.edit);
  useEffect(() => {
    if (!formSetup.edit || formSetup.edit === previousEdit) return;

    setValues({
      repaidBeforeDisbursement: pipe(
        values.repaidBeforeDisbursement,
        toFalse,
        option.some
      ),
      changeLimit: pipe(values.changeLimit, toFalse, option.some),
    });
  }, [formSetup, previousEdit, values, setValues]);

  const handleCancel = () => {
    pipe(props.initialValues, formStateFromInitialValues, setValues);
    props.onCancel();
  };

  const providerFieldProps = fieldProps("provider");
  const providerOnChange = (value: Option<DropdownOption<NonEmptyString>>) => {
    providerFieldProps.onChange(dropdownOptionToValue(value));
  };

  const displayedFields = getDisplayedFields(values, formSetup, props.variant);

  const displayProductIsUsed = () => {
    if (props.variant === "OVD" && r8EnableSimpleRefinancing) {
      return <></>;
    }

    if (
      r8EnableSimpleRefinancing &&
      toFalse(
        pipe(
          props.initialValues,
          option.chain(i => i.includedInBonita)
        )
      )
    ) {
      return (
        <InlineMessage
          type="informative"
          size="medium"
          message={formatMessage(
            "Mortgage.CBResults.Liabilities.loan.includedInBonita"
          )}
        />
      );
    } else {
      return (
        <InlineMessage
          type="informative"
          size="medium"
          message={formatMessage(
            "Mortgage.CBResults.Liabilities.loan.notIncludedInBonita"
          )}
        />
      );
    }
  };

  const removeUCBProviderCode = (
    providersList: DropdownOption<NonEmptyString>[],
    manuallyAdded: boolean
  ) => {
    return !manuallyAdded
      ? providersList
      : providersList.filter(
          provider =>
            provider.value !=
            foldTenant(
              tenant,
              () => "1111",
              () => "2700"
            )
        );
  };

  return (
    <FormSection>
      {(displayedFields.currentBalance || displayedFields.creditCardLimit) && (
        <FormRow type="1-1">
          {displayedFields.currentBalance ? (
            <OptionalEditMoneyField
              {...fieldProps("currentBalance")}
              min={props.amountLimits.ccAndOvdCurrentBalance.min}
              max={props.amountLimits.ccAndOvdCurrentBalance.max}
              currency={currency}
              label={formatMessage(
                "Liabilities.AddConsumerLoanOrMortgage.currentBalanceLabel"
              )}
              placeholder={formatMessage(
                "Liabilities.AddConsumerLoanOrMortgage.currentBalanceLabel"
              )}
              edit={
                (formSetup.edit && formSetup.manuallyAdded) ||
                (r8EnableSimpleRefinancing &&
                  !option.isSome(fieldProps("currentBalance").value))
              }
              issues={formatIssues(
                "currentBalance",
                fieldProps("currentBalance").issues
              )}
              feedback={formatFeedback("currentBalance")}
            />
          ) : null}
          {displayedFields.creditCardLimit ? (
            <OptionalEditMoneyField
              {...fieldProps("creditCardLimit")}
              min={props.amountLimits.ccAndOvdLimit.min}
              max={props.amountLimits.ccAndOvdLimit.max}
              currency={currency}
              label={formatMessage(
                "Liabilities.AddCreditCard.creditCardLimitLabel"
              )}
              placeholder={formatMessage(
                "Liabilities.AddCreditCard.creditCardLimitLabel"
              )}
              edit={
                (formSetup.edit && formSetup.manuallyAdded) ||
                (r8EnableSimpleRefinancing &&
                  !option.isSome(fieldProps("creditCardLimit").value))
              }
              issues={formatIssues(
                "creditCardLimit",
                fieldProps("creditCardLimit").issues
              )}
              feedback={formatFeedback("creditCardLimit")}
            />
          ) : null}
        </FormRow>
      )}

      {displayedFields.repaidBeforeDisbursement && formSetup.edit && (
        <FormRow type="full">
          <CheckboxField
            {...fieldProps("repaidBeforeDisbursement")}
            label={formatMessage(
              "Mortgage.CBResults.Liabilities.repaidBeforeDisbursement"
            )}
            value={pipe(fieldProps("repaidBeforeDisbursement").value, toFalse)}
            onChange={(newValue: boolean) =>
              pipe(
                newValue,
                option.some,
                fieldProps("repaidBeforeDisbursement").onChange
              )
            }
            issues={formatIssues(
              "repaidBeforeDisbursement",
              fieldProps("repaidBeforeDisbursement").issues
            )}
          />
        </FormRow>
      )}

      {displayedFields.changeLimit && formSetup.edit && (
        <FormRow type="full">
          <CheckboxField
            {...fieldProps("changeLimit")}
            label={formatMessage("Mortgage.CBResults.Liabilities.changeLimit")}
            description={formatMessage(
              "Mortgage.CBResults.Liabilities.toBeProvidedNote.creditCard"
            )}
            value={pipe(fieldProps("changeLimit").value, toFalse)}
            onChange={(newValue: boolean) =>
              pipe(newValue, option.some, fieldProps("changeLimit").onChange)
            }
          />
        </FormRow>
      )}

      {getChangeLimitDisbursementConflict(
        values.repaidBeforeDisbursement,
        values.changeLimit
      ) && (
        <Banner
          type="error"
          content={formatMessage(
            "Mortgage.CBResults.Liabilities.creditCardChangeLimitError"
          )}
          actions={option.none}
          title={option.none}
        />
      )}

      {(displayedFields.provider || displayedFields.contractNumber) && (
        <FormRow type="1-1">
          {displayedFields.provider ? (
            <OptionalEditDropdownField
              {...providerFieldProps}
              clearable={false}
              searchable
              label={formatMessage(
                !formSetup.isACPhase && formSetup.edit
                  ? "Liabilities.AddConsumerLoanOrMortgage.providerLabelOptional"
                  : "Liabilities.AddConsumerLoanOrMortgage.providerLabel"
              )}
              placeholder={formatMessage(
                "Liabilities.AddConsumerLoanOrMortgage.providerLabel"
              )}
              options={removeUCBProviderCode(
                props.providersList,
                formSetup.manuallyAdded
              )}
              value={selectedDropdownOption(
                providerFieldProps.value,
                props.providersList
              )}
              onChange={providerOnChange}
              edit={formSetup.edit}
              issues={formatIssues("provider", fieldProps("provider").issues)}
              feedback={formatFeedback("provider")}
            />
          ) : null}

          {displayedFields.contractNumber ? (
            <OptionalEditTextField
              {...fieldProps("contractNumber")}
              value={pipe(
                fieldProps("contractNumber").value,
                option.getOrElse(constant(""))
              )}
              onChange={value =>
                pipe(
                  value,
                  option.fromPredicate(value => value.length > 0),
                  fieldProps("contractNumber").onChange
                )
              }
              label={formatMessage(
                !formSetup.isACPhase && formSetup.edit
                  ? "Mortgage.CBResults.Liabilities.contractNumberOptional"
                  : "Mortgage.CBResults.Liabilities.contractNumber"
              )}
              placeholder={formatMessage(
                "Mortgage.CBResults.Liabilities.contractNumber"
              )}
              edit={formSetup.edit}
              issues={formatIssues(
                "contractNumber",
                fieldProps("contractNumber").issues
              )}
              feedback={formatFeedback("contractNumber")}
            />
          ) : null}
        </FormRow>
      )}

      {displayedFields.newCreditCardLimit && (
        <FormRow type="1-1">
          <OptionalEditMoneyField
            {...fieldProps("newCreditCardLimit")}
            min={0}
            max={999999999}
            currency={currency}
            label={formatMessage(
              "Mortgage.CBResults.Liabilities.newCreditCardLimit"
            )}
            placeholder={formatMessage(
              "Mortgage.CBResults.Liabilities.newCreditCardLimit"
            )}
            edit={formSetup.edit}
            issues={formatIssues(
              "newCreditCardLimit",
              fieldProps("newCreditCardLimit").issues
            )}
            feedback={formatFeedback("newCreditCardLimit")}
          />
          {null}
        </FormRow>
      )}

      {!formSetup.edit && toFalse(values.changeLimit) && (
        <InlineMessage
          type="informative"
          size="medium"
          message={formatMessage(
            "Mortgage.CBResults.Liabilities.creditCardChangeLimitInfo"
          )}
        />
      )}
      {!formSetup.edit && toFalse(values.repaidBeforeDisbursement) && (
        <InlineMessage
          type="informative"
          size="medium"
          message={formatMessage(
            "Mortgage.CBResults.Liabilities.repaidBeforeDisbursement"
          )}
        />
      )}
      {!formSetup.edit &&
      toFalse(
        pipe(
          props.initialValues,
          option.chain(i => i.usedForRefinance)
        )
      ) &&
      props.variant !== "OVD" ? (
        <InlineMessage
          type="informative"
          size="medium"
          message={formatMessage(
            "Mortgage.CBResults.Liabilities.cc.selectedForRefinancing"
          )}
        />
      ) : null}

      {displayProductIsUsed()}

      {formSetup.isACPhase &&
        !formSetup.edit &&
        !checkRequiredFieldsProvided(
          values,
          getRequiredFields(values, formSetup, props.variant)
        ) &&
        (toFalse(values.changeLimit) ||
          toFalse(values.repaidBeforeDisbursement)) && (
          <Banner
            type="warning"
            content={formatMessage(
              "Mortgage.CBResults.Liabilities.provideMandatoryDetailsWarning"
            )}
            onDismiss={option.none}
            actions={option.none}
            title={option.none}
          />
        )}

      {formSetup.edit ? (
        <ActionButtonGroup
          hAlignContent="right"
          actions={[
            {
              action: handleCancel,
              label: formatMessage("Cancel"),
              disabled: false,
              variant: "text",
            }, // as Action,
            {
              action: handleSubmit,
              labels: pipe(
                props.initialValues,
                option.fold(
                  () => ({
                    normal: formatMessage("Liabilities.Add"),
                    loading: formatMessage("Liabilities.Add"),
                    error: formatMessage("Liabilities.Add"),
                    success: formatMessage("Liabilities.Add"),
                  }),
                  () => ({
                    normal: formatMessage("Save"),
                    loading: formatMessage("Save"),
                    error: formatMessage("Save"),
                    success: formatMessage("Save"),
                  })
                )
              ),
              disabled: false,
              variant: "loading",
              type: "submit",
            }, // as Action
          ]}
        />
      ) : (
        <Box vAlignContent="center">
          <Space fluid />

          {formSetup.manuallyAdded && !props.isViewMode && (
            <Button
              disabled={props.isInteractingWithAnyForm}
              variant="text"
              size="default"
              label={formatMessage(
                "Mortgage.CBResults.Liabilities.removeLabel"
              )}
              action={props.onRemove}
            />
          )}
          <Space units={8} />
          {foldLiabilitiesVariant(props.variant, {
            onCf: () =>
              formSetup.manuallyAdded ? (
                <Button
                  disabled={props.isInteractingWithAnyForm}
                  variant="text"
                  size="default"
                  label={formatMessage(
                    "Mortgage.CBResults.Liabilities.editLabel"
                  )}
                  action={props.onEdit}
                />
              ) : null,
            onOvd: () =>
              formSetup.manuallyAdded ? (
                <Button
                  disabled={props.isInteractingWithAnyForm}
                  variant="text"
                  size="default"
                  label={formatMessage(
                    "Mortgage.CBResults.Liabilities.editLabel"
                  )}
                  action={props.onEdit}
                />
              ) : null,
            onMtg: () =>
              pipe(
                props.isViewMode,
                boolean.fold(
                  () => (
                    <Button
                      disabled={props.isInteractingWithAnyForm}
                      variant="text"
                      size="default"
                      label={formatMessage(
                        "Mortgage.CBResults.Liabilities.editLabel"
                      )}
                      action={props.onEdit}
                    />
                  ),
                  constNull
                )
              ),
          })}
        </Box>
      )}
    </FormSection>
  );
}
