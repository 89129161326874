import {
  Box,
  Space,
  Heading,
  Stack,
  Body,
  AtIcon,
  CardIcon,
  ExpenseLimitIcon,
  SendIcon,
  AppDownloadIcon,
  PanelHeaderIcon,
  useIsTouchScreen,
  LocalizedString,
  link,
  TextChildren,
  useIsMobileLayout,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { boolean, option } from "fp-ts";
import { pipe, constNull } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import * as classes from "./SummaryAndDownloadCommonStyles.treat";
import { useFormatMessage } from "../../intl";
import { useBranchExperienceContext } from "../../BranchExperience/BranchExperienceContext";
import { PendingActionsOutput } from "./api";
import { useAppContext } from "../../useAppContext";

type CsrAndFatcaDetails = {
  title: LocalizedString;
  content: TextChildren;
};

export function PendingActions({
  stepNo,
  pendingActions,
  isSavingsAccount,
}: {
  stepNo: number;
  pendingActions: PendingActionsOutput;
  isSavingsAccount: boolean;
}) {
  const formatMessage = useFormatMessage();
  const { branchExperienceFeaturesActive } = useBranchExperienceContext();
  const isTouchScreen = useIsTouchScreen();
  const isMobileLayout = useIsMobileLayout();

  const {
    config: { enableVirtualCardsImpl },
  } = useAppContext();

  const computeCrsAndFatcaDetails = (
    crsRelevant: boolean,
    fatcaRelevant: boolean
  ): Option<CsrAndFatcaDetails> => {
    if (crsRelevant && fatcaRelevant) {
      return option.some({
        title: formatMessage(
          "SummaryAndDownload.Summary.documents.crsfatca.title"
        ),
        content: [
          formatMessage("SummaryAndDownload.Summary.documents.crsfatca.part1"),
          link(
            formatMessage(
              "SummaryAndDownload.Summary.documents.crsfatca.link1.url"
            ),
            formatMessage(
              "SummaryAndDownload.Summary.documents.crsfatca.link1.description"
            )
          ),
          formatMessage("SummaryAndDownload.Summary.documents.crsfatca.part2"),
        ],
      });
    } else if (crsRelevant && !fatcaRelevant) {
      return option.some({
        title: formatMessage("SummaryAndDownload.Summary.documents.crs.title"),
        content: [
          formatMessage("SummaryAndDownload.Summary.documents.crs.part1"),
          link(
            formatMessage("SummaryAndDownload.Summary.documents.crs.link1.url"),
            formatMessage(
              "SummaryAndDownload.Summary.documents.crs.link1.description"
            )
          ),
          formatMessage("SummaryAndDownload.Summary.documents.crs.part2"),
        ],
      });
    } else if (!crsRelevant && fatcaRelevant) {
      return option.some({
        title: formatMessage(
          "SummaryAndDownload.Summary.documents.fatca.title"
        ),
        content: [
          formatMessage("SummaryAndDownload.Summary.documents.fatca.part1"),
          link(
            formatMessage(
              "SummaryAndDownload.Summary.documents.fatca.link1.url"
            ),
            formatMessage(
              "SummaryAndDownload.Summary.documents.fatca.link1.description"
            )
          ),
          formatMessage("SummaryAndDownload.Summary.documents.fatca.part2"),
        ],
      });
    }
    return option.none;
  };

  const pendingAction = (
    icon: JSX.Element,
    title: Option<LocalizedString>,
    content: TextChildren
  ) => (
    <Box vAlignContent="top">
      <Box shrink={false}>{icon}</Box>
      <Space units={4} />
      <Box column grow shrink>
        {pipe(
          title,
          option.map(title => (
            <Heading size="x-small" weight="medium">
              {title}
            </Heading>
          )),
          option.toNullable
        )}
        <Space units={2} />
        <Body size="medium" weight="regular" color={palette.neutral700}>
          {content}
        </Body>
      </Box>
    </Box>
  );

  const renderCrsAndFatcaChecks = (
    crsRelevant: boolean,
    fatcaRelevant: boolean
  ): JSX.Element | null => {
    const details = computeCrsAndFatcaDetails(crsRelevant, fatcaRelevant);
    return pipe(
      details,
      option.fold(constNull, (details: CsrAndFatcaDetails) => {
        const { title, content } = details;
        return pendingAction(
          <SendIcon size="large" monochromatic color={palette.neutral800} />,
          option.some(title),
          content
        );
      })
    );
  };

  const pinRelevant = enableVirtualCardsImpl && !isSavingsAccount;

  return pipe(
    branchExperienceFeaturesActive && !isTouchScreen,
    boolean.fold(
      () => {
        if (
          pendingActions.emailVerified &&
          !pendingActions.crsRelevant &&
          !pendingActions.fatcaRelevant &&
          !pendingActions.overdraftAvailability &&
          !pinRelevant
        )
          return null;
        else
          return (
            <Box column>
              <Space units={20} />
              <Box column className={classes.wrapper}>
                <Stack units={4} vAlignContent="center">
                  <PanelHeaderIcon
                    variant="active"
                    type="number"
                    value={stepNo}
                  />
                  <Heading size="small" weight="medium">
                    {formatMessage("SummaryAndDownload.Summary.pendingAction")}
                  </Heading>
                </Stack>
                <Space units={9} />
                <Box shrink>
                  {!isMobileLayout && <Space units={11} />}
                  <Stack column units={8} shrink>
                    {!pendingActions.emailVerified &&
                      pendingAction(
                        <AtIcon
                          size="large"
                          monochromatic
                          color={palette.neutral800}
                        />,
                        option.some(
                          formatMessage(
                            "SummaryAndDownload.Summary.verify.email.title"
                          )
                        ),
                        formatMessage(
                          "SummaryAndDownload.Summary.verify.email.description"
                        )
                      )}
                    {pinRelevant &&
                      pendingAction(
                        <CardIcon
                          size="large"
                          monochromatic
                          color={palette.neutral800}
                        />,
                        option.some(
                          formatMessage(
                            "SummaryAndDownload.Summary.setPin.title"
                          )
                        ),
                        formatMessage(
                          "SummaryAndDownload.Summary.setPin.description"
                        )
                      )}
                    {renderCrsAndFatcaChecks(
                      pendingActions.crsRelevant,
                      pendingActions.fatcaRelevant
                    )}
                    {pendingActions.overdraftAvailability &&
                      pendingAction(
                        <ExpenseLimitIcon
                          size="large"
                          monochromatic
                          color={palette.neutral800}
                        />,
                        option.some(
                          formatMessage(
                            "SummaryAndDownload.Summary.overdraft.title"
                          )
                        ),
                        formatMessage(
                          "SummaryAndDownload.Summary.overdraft.description"
                        )
                      )}
                  </Stack>
                </Box>
              </Box>
            </Box>
          );
      },
      () => {
        return (
          <Stack units={8} column shrink>
            {pendingActions.sbRelevant &&
              pendingAction(
                <AppDownloadIcon
                  size="large"
                  monochromatic
                  color={palette.neutral800}
                />,
                option.some(
                  formatMessage(
                    "SummaryAndDownload.Summary.download.branchExperience.title"
                  )
                ),
                formatMessage(
                  "SummaryAndDownload.Summary.download.branchExperience.description"
                )
              )}
            {!pendingActions.emailVerified &&
              pendingAction(
                <AtIcon
                  size="large"
                  monochromatic
                  color={palette.neutral800}
                />,
                option.some(
                  formatMessage(
                    "SummaryAndDownload.Summary.verify.email.branchExperience.title"
                  )
                ),
                formatMessage(
                  "SummaryAndDownload.Summary.verify.email.branchExperience.description"
                )
              )}
            {pinRelevant &&
              pendingAction(
                <CardIcon
                  size="large"
                  monochromatic
                  color={palette.neutral800}
                />,
                option.some(
                  formatMessage("SummaryAndDownload.Summary.setPin.title")
                ),
                formatMessage("SummaryAndDownload.Summary.setPin.description")
              )}
            {renderCrsAndFatcaChecks(
              pendingActions.crsRelevant,
              pendingActions.fatcaRelevant
            )}
            {pendingActions.overdraftAvailability &&
              pendingAction(
                <ExpenseLimitIcon
                  size="large"
                  monochromatic
                  color={palette.neutral800}
                />,
                option.some(
                  formatMessage(
                    "SummaryAndDownload.Summary.overdraft.branchExperience.title"
                  )
                ),
                formatMessage(
                  "SummaryAndDownload.Summary.overdraft.branchExperience.description"
                )
              )}
          </Stack>
        );
      }
    )
  );
}
